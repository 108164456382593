<template>
  <div class="elv-journal-coa-dropdown-content">
    <el-select
      v-bind="$attrs"
      ref="selectRef"
      :teleported="($attrs?.teleported as boolean) ?? false"
      popper-class="elv-journal-coa-dropdown-popper"
      :placeholder="($attrs?.placeholder as string) ?? t('placeholder.selectAccount')"
      :clearable="($attrs?.clearable as boolean) ?? true"
      @change="onChangeSelect"
      @clear="onClearSelect"
    >
      <el-scrollbar wrap-class="elv-journal-coa-dropdown-menu-scrollbar__wrap">
        <el-option-group
          v-for="(item, i) in chartOfAccountOptions"
          :key="i"
          class="elv-journal-coa-dropdown-menu"
          :label="locale === 'en' ? item.name : item?.nameCN || item.name"
          :class="{
            'is-selected': selectedMenu === item.type,
            'is-treasury_account': item.type === 'TREASURY_ACCOUNT'
          }"
          @click="onSelectMenu(item.type, $event)"
        >
          <el-scrollbar
            :height="250"
            wrap-class="elv-journal-coa-dropdown-list-scrollbar__wrap"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave"
          >
            <!-- <template> -->
            <el-option
              v-for="children in item.list"
              v-show="selectedMenu === item.type"
              :key="children.chartOfAccountId"
              class="elv-journal-coa-dropdown-item"
              :label="locale === 'en' ? children.name : children?.nameCN || children.name"
              :value="children.chartOfAccountId"
            />
            <!-- </template> -->
          </el-scrollbar>
        </el-option-group>
      </el-scrollbar>
    </el-select>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { find, isEmpty } from 'lodash-es'
import { useEntityStore } from '@/stores/modules/entity'

const props = defineProps({
  showTreasuryAccount: {
    type: Boolean,
    default: false
  }
})

const { t, locale } = useI18n()
const selectedMenu = ref('')
const selectRef = useTemplateRef('selectRef')
const attrs: any = useAttrs()
const entityStore = useEntityStore()

const emit = defineEmits(['onChangeChartOfAccount', 'onSelectTreasuryAccount'])

const chartOfAccountOptions = computed(() => {
  return props.showTreasuryAccount
    ? [
        ...entityStore.chartOfAccountOriginList,
        {
          name: `{ ${t('report.treasuryAccount')} }`,
          nameCN: `{ ${t('report.treasuryAccount')} }`,
          type: 'TREASURY_ACCOUNT',
          list: [
            {
              chartOfAccountId: '0',
              name: `{ ${t('report.treasuryAccount')} }`,
              nameCN: `{ ${t('report.treasuryAccount')} }`
            }
          ]
        }
      ]
    : entityStore.chartOfAccountOriginList
})

const onSelectMenu = (menu: string, e: any) => {
  if (menu !== 'TREASURY_ACCOUNT') {
    e.stopPropagation()
    selectedMenu.value = menu
  } else {
    emit('onSelectTreasuryAccount')
    selectRef.value?.blur()
  }
}

const onMouseEnter = () => {
  const verticalScrollbarElement: any = document.querySelector(
    '.elv-journal-coa-dropdown-menu-scrollbar__wrap + .el-scrollbar__bar + .is-vertical'
  )
  if (verticalScrollbarElement) {
    verticalScrollbarElement.style.width = '0px'
  }
}

const onMouseLeave = () => {
  const verticalScrollbarElement: any = document.querySelector(
    '.elv-journal-coa-dropdown-menu-scrollbar__wrap + .el-scrollbar__bar + .is-vertical'
  )
  if (verticalScrollbarElement) {
    verticalScrollbarElement.style.width = '6px'
  }
}

const onChangeSelect = () => {
  if (attrs.modelValue !== '' && attrs.modelValue) {
    selectedMenu.value =
      find(entityStore.chartOfAccountList, { chartOfAccountId: attrs.modelValue })?.type ??
      chartOfAccountOptions.value[0]?.type
  }
  emit('onChangeChartOfAccount')
}

const onClearSelect = () => {
  selectedMenu.value = chartOfAccountOptions.value[0]?.type
}

defineExpose({
  handleClickOutside: selectRef
})

watch(
  [() => entityStore.chartOfAccountOriginList, () => attrs.modelValue],
  () => {
    if (!isEmpty(entityStore.chartOfAccountOriginList)) {
      selectedMenu.value = entityStore.chartOfAccountOriginList[0]?.type
      if (attrs.modelValue) {
        selectedMenu.value =
          find(entityStore.chartOfAccountList, { chartOfAccountId: attrs.modelValue })?.type ??
          chartOfAccountOptions.value[0]?.type
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-journal-coa-dropdown-content {
  width: 546px;
  height: 44px;

  .el-select {
    .el-select__wrapper {
      height: 44px;
    }
  }
}

.el-popper.elv-journal-coa-dropdown-popper {
  background-color: #fff;
  height: 250px;
  width: 650px;
  overflow: hidden;
  box-sizing: border-box;

  .el-scrollbar__view.el-select-dropdown__list {
    height: 250px;
    width: 179px;
  }

  .elv-journal-coa-dropdown-filter-item {
    color: #1e2024;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &.is-selected {
      color: #1e2024;
      background-color: #edf0f3;
    }
  }

  .elv-journal-coa-dropdown-menu-scrollbar__wrap {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .el-select-dropdown__wrap.el-scrollbar__wrap:has(.el-select-dropdown__list) {
    position: relative;
  }

  .elv-journal-coa-dropdown-menu {
    display: flex !important;
    border-bottom: 0px;
    width: 179px;
    height: 32px;
    cursor: pointer;

    &.is-selected {
      background-color: #edf0f3;

      .el-select-group__title {
        + li:has(.el-select-group):has(.elv-journal-coa-dropdown-item) {
          z-index: 77;
        }
      }
    }

    &.is-treasury_account {
      .el-select-group__title {
        &::after {
          content: none;
        }
      }
    }

    &:not(.is-selected):hover {
      background-color: #f9fafb;
    }

    .elv-journal-coa-dropdown-item {
      color: #1e2024;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &.is-selected {
        color: #1e2024;
        background-color: #edf0f3;
      }
    }

    .el-select-group__title {
      width: 154px;
      border-bottom: 0px;
      padding-right: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      display: block;
      -webkit-line-clamp: 1;
      line-height: 32px;

      + li:has(.el-select-group):has(.elv-journal-coa-dropdown-item) {
        position: fixed;
        top: 0;
        left: 179px;
        width: 471px;
        z-index: 2;
        box-sizing: border-box;
        border-left: 1px solid #edf0f3;
      }

      &::after {
        content: '';
        position: absolute;
        background-image: url('@/assets/img/chevron--right.png');
        background-size: cover;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
      }
    }
  }

  &.el-select-dropdown {
    border-radius: 0;
    height: 250px;

    :first-child {
      &.is-empty {
        display: none;
      }
    }
  }

  .elv-journal-coa-dropdown-general-journal {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 12px 8px;
    box-sizing: border-box;
    align-items: center;
    color: #1753eb;
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    border-top: 1px solid #dde1e6;

    svg {
      margin-right: 8px;
    }
  }
}
</style>
